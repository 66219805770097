const propertyData = {
    "url": "http://348nwsixthave.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "348 NW Sixth Ave", "city": "Canby", "state":"Oregon", "zipcode": "97013"},
    "display_address": "348 NW Sixth Ave",
    "property": {
      "listing_price": "$359,900",
      "bedrooms": "3",
      "baths": "1.5",
      "sq_feet": "1768",
      "lot_size": "14810.4",
      "rmls": "21152788",
      "built_in": "1915",
      "blurb": "Charming well-maintained ranch style home located in a lovely neighborhood. Home features 3beds/1.5bath with newer roof, plus new interior paint, flooring, certified woodstove, and ductless heater. Kitchen has new cabinets and new backsplash. Recent roof maintenance, and exterior has been power washed. Huge backyard is level and fenced with garden bed, tool shed, patio, pool, & covered area. Bonus workshop area next to garage and RV parking. Convenient to park, school, shopping & downtown area.",
      "ammenities": [

      ]
    },
    "image_count": 22,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2808.0961621161027!2d-122.7011887844492!3d45.26606687909919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495657f09d3d4b7%3A0xa1c2de02d2b37105!2s348%20NW%206th%20Ave%2C%20Canby%2C%20OR%2097013!5e0!3m2!1sen!2sus!4v1611851416367!5m2!1sen!2sus",
        "youtube":"sYt5EB-FJu0",
        "matterport":"sNrexuy75qY"
    }
  }

export default propertyData;

